export const convertToAttributes = (addOns) => {
    const addOnAttributesTemp = {};
    for (let addOnIndex = 0; addOnIndex < addOns.length; addOnIndex++) {
      const addOn = addOns[addOnIndex];
      addOnAttributesTemp[`data-item-custom${addOnIndex + 1}-name`] = addOn.name;
      var addOnPrices = convertToOptions(addOn);
      addOnAttributesTemp[`data-item-custom${addOnIndex + 1}-options`] = addOnPrices;
    }
    return addOnAttributesTemp;
  }

export const convertToOptions = (addOn) => {
    console.log("convertToOptions")
    console.log(addOn)
    return addOn.choices ?
      convertToOptionsWithChoices(addOn) :
      [...Array(addOn.max + 1).keys()]
        .map(i => i * addOn.price)
        .map((addOnPrice, index) => index === 0 ? 0 : `${index}[+${parseFloat(addOnPrice)}]`)
        .join('|');
}

const convertToOptionsWithChoices = (addOn) => {
  const choices = addOn.choices.split(',').map(c => c.trim());
  const options = addOn.isRequired ? choices : ['', ...choices];
  return options.join('|');
}