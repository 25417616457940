import React, { useEffect, useState } from 'react';
import 'antd/dist/reset.css';
import { Menu,Affix } from 'antd';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { eventBus } from '../event-buses/event-bus';
import { navBarEvents } from '../event-buses/event-names';

const NavBar = ({categories}) => {  

    const [selectedKeys, setSelectedKeys] = useState(categories ? [categories[0].node.id] : [])
    
    useEffect(() => {
        console.log("Side bar nav bar changed");
        console.log(selectedKeys)
    }, [selectedKeys])

    useEffect(() => {
        eventBus.on(navBarEvents.narBarSelectedItemChanging, (selectedId) => {
            console.log('Nav bar new selected item id ' + selectedId);
            setSelectedKeys([selectedId])
        })
        return () => {
            eventBus.remove(navBarEvents.narBarSelectedItemChanging)
        }
    }, [])

    const handleMenuItemClick = (item) => {
        scrollTo("#"+item.node.id, "start");
        setSelectedKeys([item.node.id]);
    }

    return ( 
        <Affix offsetTop={50} className="Sidebar__Wrapper">
            <div className='Sidebar'>
                <Menu 
                    className='SidebarMenu'           
                    mode="inline"
                    selectedKeys={selectedKeys}
                >
                    {
                        categories.map((item,index) => (
                            <Menu.Item className='SidebarMenu___Name'
                                key={item.node.id}
                            >
                                <a onClick={() => handleMenuItemClick(item)}> {item.node.name}</a>                               
                            </Menu.Item>
                        ))   
                    }           
                </Menu>
            </div>
        </Affix>       
    );
  };

export default NavBar;