export const isObjectNull = object => {
    return object === undefined || object === null || object.length === 0;
};

export const pad2 = (number) => {
   
    return (number < 10 ? '0' : '') + number
  
}


export const sortByName = (a,b) => {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }