import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/reset.css';
import CatalogueItem from './catalogue-item.component';
import { eventBus } from '../event-buses/event-bus';
import { navBarEvents } from '../event-buses/event-names';

const CatalogueCategory = ({category, products, storeSetting}) => {
      const [isCategoryTop, setIsCategoryTop] = useState(false)
      const categoryHeaderRef = useRef(null)

      useEffect(() => {
        const handleScrollAndResize = () => {
          const screenHeight = window.innerHeight;
          const halfScreenHeight = screenHeight / 2;
          const current = categoryHeaderRef.current;
          const top = current.getBoundingClientRect().top;
          setIsCategoryTop(top > 0 && top <= halfScreenHeight);
        };
    
        handleScrollAndResize();
    
        window.addEventListener('scroll', handleScrollAndResize);
        window.addEventListener('resize', handleScrollAndResize);
    
        return () => {
          window.removeEventListener('scroll', handleScrollAndResize);
          window.removeEventListener('resize', handleScrollAndResize);
        };
      }, [])

      useEffect(() => {
        if (isCategoryTop) {
          eventBus.dispatch(navBarEvents.narBarSelectedItemChanging, category.id)
        }
      }, [isCategoryTop])

      return ( 
        <>
          <div key={category.id} className="Catalogue__category" ref={categoryHeaderRef}>
            <div className="category-name text-center">
              <span id={category.id}>{category.name}</span>
            </div>
            { category.description && category.description !== '' && 
              <div className="category-description text-center">
                <span>{category.description}</span>
              </div>
            }
          </div>
          {products.edges
            .filter(p => p.node.category.id === category.id)
            .map(({ node: product }) => (
              <CatalogueItem key={product.id} product={product} addOns={category.addOns} settingEnableCheckout={storeSetting}>
              </CatalogueItem>
          ))}   
        </>
    );
  };

export default CatalogueCategory;