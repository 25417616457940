import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from "../layouts/index"
import SideCart from "../components/side-cart.component"
import NavBar from "../components/navbar.component"
import { getContentBodyByKey, getSettingValueByName, getEnableCheckoutSetting, getStoreAvailabilitySetting } from "../shared/dato-cms-helper"
import { getDateTimeFromHHMM, getTodayClosedPeriodsAsync} from '../shared/date-helper'
// import { isMonday } from '../shared/date-helper'
import Holidays from 'date-holidays'
import { eventBus } from '../event-buses/event-bus'
import { checkoutEvents } from '../event-buses/event-names'
import CatalogueCategory from '../components/catalogue-category.component'

export default () => {

  return (
    <StaticQuery
      query={graphql`
        query CatalogueQuery {
          products: allDatoCmsProduct(sort: {fields: order, order: ASC}) {
            edges {
              node {
                id
                name
                price
                description
                isOutOfStock
                image {
                  url
                  sizes(maxWidth: 300, imgixParams: { fm: "jpg" }) {
                    ...GatsbyDatoCmsSizes
                  }
                }
                category {
                  id
                }
                dietaries {
                  id
                  name
                  description
                  image {
                    url
                    sizes(maxWidth: 50, imgixParams: { fm: "png" }) {
                      ...GatsbyDatoCmsSizes
                    }
                  }
                }
                addOns: addons {
                  id
                  name
                  price
                  max
                  choices
                  isRequired
                }
              }
            }
          }
          categories: allDatoCmsProductCategory(sort: {fields: order, order: ASC}) {
            edges {
              node {
                id
                name
                description
                order
                addOns: addons {
                  id
                  name
                  price
                  max
                  choices
                  isRequired
                }
              }
            }
          }
          settings: allDatoCmsSetting {
            nodes {
              id
              name
              value
            }
          }
          contents: allDatoCmsContent {
            edges {
              node {
                id
                header
                key
                body
              }
            }
          }
          storeAvailabilitySetting: allDatoCmsStoreAvailabilitySetting {
            edges {
              node {
                openHour
                openMinute
                closedNotice
                closedDaysOfWeek
                closeMinute
                closeHour
              }
            }
          }
          site {
            siteMetadata {
              siteName
            }
          }
        }
    `}
    render={(data) => {
      console.log('Server data')
      console.log(data)
      const settingStoreAvailability = getStoreAvailabilitySetting(data)
      const settingEnableCheckout = getEnableCheckoutSetting(data)
      const settingPhoneNumber = getSettingValueByName(data, 'Phone Call Number')
      const menuDisclaimerContent = getContentBodyByKey(data, 'Menu Disclaimer')

      // const [currentClosedPeriod, setCurrentClosedPeriod] = useState();
      const [todayClosedPeriods, setTodayClosedPeriods] = useState();
      const [noticeMessages,setNoticeMessages] = useState(false);
      const [storeSetting, setStoreSetting] = useState(
        {
          isLoading: true,
          isEnableCheckOut: true,
          storeOpeningSetting: settingStoreAvailability
        });
      const [newEventTime, setNewEventTime] = useState(new Date())

      useEffect(() => {
        // let mounted = true;
        // fetchCurrentClosedPeriodAsync().then((data) =>{
        //   mounted && setCurrentClosedPeriod(data)
        // })

        getLatestTodayClosedPeriods(setTodayClosedPeriods, storeSetting, setStoreSetting)

        const isHoliday = isHolidayInAustralia()

        const openDateTime = getDateTimeFromHHMM(settingStoreAvailability.openHour, settingStoreAvailability.openMinute)
        const closeDateTime = getDateTimeFromHHMM(settingStoreAvailability.closeHour, settingStoreAvailability.closeMinute)

        const newStoreSetting = {
          ...storeSetting,
          openDateTime: openDateTime,
          closeDateTime: closeDateTime,
          isEnableCheckOut: settingEnableCheckout && !isHoliday
        }
        setStoreSetting(newStoreSetting)

        eventBus.on(checkoutEvents.checkoutClicked, () => {
          getLatestTodayClosedPeriods(setTodayClosedPeriods, storeSetting, setStoreSetting, () => {
            setNewEventTime(new Date())
          })
        });

        return () => { 
          // mounted = false; 
        }
      }, []);

      useEffect(() => {
        if (storeSetting.isLoading)
          return;
        eventBus.dispatch(checkoutEvents.checkoutTodayClosedPeriodRefreshed, storeSetting);
      }, [newEventTime])

      useEffect(() => console.log('Enable Checkout : '  + storeSetting), [storeSetting]);

      useEffect(() => {
        console.log("todayClosedPeriods changed")
        console.log(todayClosedPeriods)
        if (!todayClosedPeriods)
        {
          setNoticeMessages([])
          return;
        }
        const noticeMessages = todayClosedPeriods.map((p) => {
          return p.closedNotice
        })
        setNoticeMessages(noticeMessages)
      }, [todayClosedPeriods])

      return (
        <Layout site={data.site} phoneNumber = {settingPhoneNumber} noticeMessages={noticeMessages}>
          <NavBar categories={data.categories.edges}></NavBar>
          <div className="Catalogue">
            {data.categories.edges
              .filter(c => data.products.edges.some(p => p.node.category.id === c.node.id))
              .map(({node:category}) => (
                <CatalogueCategory category={category} products={data.products} storeSetting={storeSetting}></CatalogueCategory>
            ))}
          </div>
          <SideCart
            key={storeSetting}
            storeSetting={storeSetting}
            menuDisclaimerContent={menuDisclaimerContent}></SideCart>
        </Layout>
      )
      } 
    }
  />)
}

const getLatestTodayClosedPeriods = (setTodayClosedPeriods, storeSetting, setStoreSetting, callback) => {
  getTodayClosedPeriodsAsync().then((result) => {
    // Set today closed periods
    const isHoliday = isHolidayInAustralia()
    console.log("Today is holiday: " + isHoliday)
    setTodayClosedPeriods(result.data)
    const newStoreSetting = {
      ...storeSetting,
      todayClosedPeriods: result.data,
      isEnableCheckOut: storeSetting.isEnableCheckOut && !isHoliday
    }
    if (storeSetting.isLoading) {
      newStoreSetting.isLoading = false
    }
    setStoreSetting(newStoreSetting)
    console.log(result.data)
    console.log("Store setting after initialization")
    console.log(storeSetting)

    if (callback){
      callback(newStoreSetting);
    }
  })
}

const isHolidayInAustralia = () => {
  const hd = new Holidays('AU', 'TAS', 'Hobart', { types: ['public'] })
  const today = new Date()
  hd.getHolidays(today.getFullYear())
  console.log('Holidays')
  console.log(hd)

  // Add Royal Hobart Regatta
  const royalHobartRegattaDay = new Date(today.getFullYear(), 1, 1); // 1 is February, 0 is January
  let mondaysCount = 0;

  while (mondaysCount < 2) { // Keep going until the second Monday
    if (royalHobartRegattaDay.getDay() === 1) { // 1 is Monday
      mondaysCount++;
    }
  
    if (mondaysCount < 2) {
      royalHobartRegattaDay.setDate(royalHobartRegattaDay.getDate() + 1);
    }
  }

  console.log('Royal Hobart Regatta this year is on ' + royalHobartRegattaDay)

  const regionalHolidays = [
    royalHobartRegattaDay,
    new Date(2022, 9, 20), // 9 is October, 0 is January
    new Date(2023, 9, 26),
    new Date(2024, 9, 24),
    new Date(2022, 11, 24),
    new Date(2022, 11, 25),
    new Date(2022, 11, 26),
    new Date(2022, 11, 27),
    new Date(2022, 11, 28),
    new Date(2022, 11, 29),
    new Date(2022, 11, 30),
    new Date(2022, 11, 31),
    new Date(2023, 0, 1),
    new Date(2023, 0, 2),
    new Date(2024, 9, 24), // Royal Hobart Show 2024
    new Date(2025, 9, 23), // Royal Hobart Show 2025
  ]
  const isHoliday = hd.isHoliday(today).length > 0 ||
    regionalHolidays.findIndex(holiday => holiday.getDate() === today.getDate() &&
      holiday.getMonth() === today.getMonth() &&
      holiday.getFullYear() === today.getFullYear()) >= 0
  return isHoliday
}

