import React, { useState, useEffect } from 'react'
import { Collapse } from "react-collapse"
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from 'react-icons/io'
import { FaMinus, FaPlus } from 'react-icons/fa';
import { Select } from 'antd';

const CatalogueAddOns = ({title, addOns, onChangeQuantityAddOn}) => {
  const [dropDown, setDropDown] = useState({isDropdownOpen:true, dropdownToggleIcon: <IoIosArrowDropdownCircle/>});

  const [componentAddOns, setComponentAddOns] = useState([])

  

  useEffect(() => {
    console.log("addOns changed in CatalogueAddOns");
    console.log(addOns);
    setComponentAddOns(addOns);
  }, [addOns])

  useEffect(() => {
    addOns.map(item =>{
      item.quantity = 0;
      item.value = undefined;
    })
  }, [addOns.quantity])

  const onDropdownClicked = () => {
    setDropDown(prevState => ({
        isDropdownOpen: !prevState.isDropdownOpen,
        dropdownToggleIcon:  prevState.isDropdownOpen ? <IoIosArrowDropupCircle/> : <IoIosArrowDropdownCircle/>
      }));
  }

  const onDecreaseQuantityClick = (item) => {
    item.quantity = item.quantity == 0 ? 0 : item.quantity - 1;
    const updatedAddOns = componentAddOns.map(addOn => {
      if (addOn.id === item.id) {
        return {
          ...addOn,
          quantity: item.quantity
        }
      } else {
        return addOn;
      }
    });
    onChangeQuantityAddOn(updatedAddOns);
    setComponentAddOns(updatedAddOns);
  }
  const onIncreaseQuantityClick = (item) => {
    item.quantity = item.quantity == item.max? item.quantity : item.quantity + 1;
    const updatedAddOns = componentAddOns.map(addOn => {
      if (addOn.id === item.id) {
        return {
          ...addOn,
          quantity: item.quantity
        }
      } else {
        return addOn;
      }
    });
    onChangeQuantityAddOn(updatedAddOns);
    setComponentAddOns(updatedAddOns);
  }

  const handleChange = (selectedItem, selectedValue) => {
    console.log('Add on click')
    console.log(selectedItem)
    selectedItem.quantity = 1;
    selectedItem.value = selectedValue;
    console.log("addOns");
    console.log(addOns)
    setComponentAddOns(addOns);
    onChangeQuantityAddOn(addOns);
  }

  return (
    <div className='add__on__collapse'>
        <div className='collapse__header' onClick={onDropdownClicked}>
            <h5 className='collapse__title'>{title} options</h5>
            <span className='collapse__toggle__icon'>{dropDown.dropdownToggleIcon}</span>
        </div>

        <Collapse isOpened={dropDown.isDropdownOpen}>
              <ul className='add__on__list mx-3 mb-0 pt-4 px-0'>
                 { componentAddOns.map(item => (
                      !item.choices ? 
                          <li className='add__on__item d-flex align-items-center' key={item.id}>
                              <button
                                disabled = {item.quantity === 0}
                                className="btn RedButton decrease__btn"
                                onClick={() => onDecreaseQuantityClick(item)}>
                                <FaMinus />{" "}

                              </button>

                              <button
                                disabled = {item.quantity === item.max}
                                className="btn RedButton increase__btn ms-2"
                                onClick={() => onIncreaseQuantityClick(item)}
                              >
                                {" "}<FaPlus />
                              </button>

                              <input disabled min={0} max={item.quantity} value={item.quantity} className="rounded text-center py-1 px-2 border mx-3" id={item.id} type="number"/>
                              <div className='border-bottom flex-fill'>
                                <span>{item.name}</span>
                                &nbsp; - &nbsp;
                                <span>{`$${item.price}`}</span>
                              </div>
  
                          </li>
                          :
                          <li className='add__on__item d-flex align-items-center choices' key={item.id}>
                            <span className='me-2'>{item.name} 
                               {item.isRequired && <span className='addOnRequiredAterisk'> *</span>}
                            </span>
                            <Select
                              style={{width: 220}}
                              className='add-on-choice'
                              options={item.choices.split(',').map((c) => {
                                return {
                                  key: c.trim(),
                                  value: c.trim()  
                                }})}
                              value={item.value}
                              onChange={(e) => handleChange(item, e)}
                              placeholder='Please select an option'
                              required={item.isRequired}
                              >
                            </Select>
                          </li>
                          ))
                    } 
              </ul>
                  
        </Collapse>
    </div>
  );
};

export default CatalogueAddOns;
