import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import ItemDetailPopup from './item-detail-popup.component'
import { convertToAttributes } from '../shared/add-on-helper'
import { sortByName } from '../helper/utils';
import DietaryIcon from './dietary-icon.component';

const CatalogueItem = ({product, addOns, settingEnableCheckout}) => {

  const [popup, setPopup] = useState({isOpen:false, product: null});
  const [isCheckoutEnabled, setIsCheckoutEnabled] = useState(true);
  const firstAddons = groupAddons(addOns, product);
  const [addOnAttributes, setAddOnAttributes] = useState(convertToAttributes(firstAddons));
  const [productItemAddOns, setProductItemAddons] = useState([]);

  const handleOpenItemDetailPopup = (product) => {
    if (isCheckoutEnabled && !product.isOutOfStock) {
      setPopup({isOpen: true, product : product });
    }
  }

  const storeURL = process.env.GATSBY_STORE_VALIDATION_URL;

  const handleCloseItemDetailPopup = () => {
    setPopup({isOpen: false, product : null });
    const newProductItemAddOns = productItemAddOns.map(item => {
      return {
        ...item,
        value: null
      }
    })
    setProductItemAddons(newProductItemAddOns);
  }

  useEffect(() => {
    setIsCheckoutEnabled(settingEnableCheckout.isEnableCheckOut && !product.isOutOfStock )
  }, [settingEnableCheckout, product.isOutOfStock])

  useEffect(() => {
    
    const groupedProductItemAddOnsWithChoices = groupAddons(addOns, product);
    setProductItemAddons(groupedProductItemAddOnsWithChoices);
    console.log('Grouped add on')
    console.log(groupedProductItemAddOnsWithChoices)

    const addOnAttributesTemp = convertToAttributes(groupedProductItemAddOnsWithChoices);
    setAddOnAttributes(addOnAttributesTemp)
  }, [addOns, product.addons])

  return (
    <div className={isCheckoutEnabled ? "Catalogue__item" : "Catalogue__item disable"} onClick={() => handleOpenItemDetailPopup(product)}>
    <div
      id="addToCart"
      key={product.id}
      className={isCheckoutEnabled ? "Product" : "Product disable"}>
      <input type="hidden"
        className="snipcart-add-item"
        data-item-id={product.id}
        data-item-price={product.price}
        data-item-image={product.image.url}
        data-item-name={product.name}
        {...addOnAttributes}
        data-item-url={storeURL}
      ></input>
    
      <div className="Product__image">
        <figure>
          <Img sizes={product.image.sizes} className="img-fluid" />
        </figure>
      </div>{" "}

      <div className='Product__dietary'>
          {product.dietaries.sort(sortByName).map(dietary => (
            <DietaryIcon dietary={dietary}></DietaryIcon>
          ))}
      </div>
      
      <div className="Product__details">
      <div className="Product__price">
            <span>{`$${product.price}`}</span>
      </div>
      <div className="Product__name">
        <span>
          {product.name}
        </span>
      </div>
      {
        product.description &&
        <div className="Product__description">
        <span>
          {product.description}
        </span>
      </div>
      }
      </div>
      {/* {
        !product.isOutOfStock &&
        <div className={"product__header product__add" + (isCheckoutEnabled ? "" : " hidden")}>
          <span>
            Add to cart
          </span>
        </div>
      } */}
      {
        product.isOutOfStock &&
        <div className="product__header product__outOfStock">
          <span>
            OUT OF STOCK
          </span>
        </div>
      }
      
    </div>
   

      {popup.isOpen && popup.product 
        && <ItemDetailPopup
            isOpen={popup.isOpen} 
            closePopup={handleCloseItemDetailPopup}
            product={popup.product} 
            addOns = {productItemAddOns}/>}

  </div>
  );
};

export default CatalogueItem;

function groupAddons(addOns, product) {
  const combinedAddOns = [...addOns, ...product.addOns];

  const groupedProductItemAddOns = combinedAddOns.filter((item, index) => {
    const currentIndex = combinedAddOns.findIndex(
      (element) => element.id === item.id
    );
    return currentIndex === index;
  });
  const groupedProductItemAddOnsWithChoices = [];
  for (const addOn of groupedProductItemAddOns) {
    if (!addOn.choices) {
      groupedProductItemAddOnsWithChoices.push(addOn);
      continue;
    }

    if (addOn.max === 1) {
      const mappedAddOn = {
        ...addOn,
        id: addOn.id,
        name: addOn.name
      };
      groupedProductItemAddOnsWithChoices.push(mappedAddOn);
    }
    else {
      for (let i = 1; i <= addOn.max; i++) {
        const mappedAddOn = {
          ...addOn,
          id: addOn.id + '-' + i,
          name: addOn.name + ' #' + i
        };
        groupedProductItemAddOnsWithChoices.push(mappedAddOn);
      }
    }
  }
  return groupedProductItemAddOnsWithChoices;
}
