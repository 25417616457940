

export const getAllCartItemsAsync = async () =>{
   // trigger cart ready promise for case refresh page.
    await window.Snipcart.ready;

    var cartItems = window.Snipcart.store.getState().cart.items.items;
    
    console.log("All Cart Items");
    console.log(cartItems)

    return cartItems;
  }