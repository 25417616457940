import React, {  } from 'react'
import Img from 'gatsby-image'
import { Tooltip } from 'antd';

const DietaryIcon = ({dietary}) => {
  return (
    <Tooltip title={dietary.description} trigger={"hover"}>
      <figure className='dietary__image' key={dietary.id}>
        <Img url={dietary.image.url} sizes={dietary.image.sizes} className="img-fluid rounded-circle" />
      </figure>
    </Tooltip>
);
};

export default DietaryIcon;