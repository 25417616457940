import React, { useState, useEffect, useRef } from 'react'
import { FaMinus, FaPlus } from "react-icons/fa";
import Img from 'gatsby-image'
import { eventBus } from '../event-buses/event-bus'
import { itemEvents } from '../event-buses/event-names'
import { convertToAttributes, convertToOptions } from '../shared/add-on-helper'
import Popup from 'reactjs-popup'
import CatalogueAddOns from './catalogue-add-on.component'
import { isObjectNull } from '../helper/utils'
import { getAllCartItemsAsync } from '../shared/cart-helper';
import DietaryIcon from './dietary-icon.component';
const ItemDetailPopup = ({isOpen, closePopup, product, addOns}) => {
  
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(product.price);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [addOnAttributes, setAddOnAttributes] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isProductValid, setIsProductValid] = useState(true);
  const containerRef = useRef(null);
  const popupRef = useRef(null);

  const handleAddItem = async () => {
    setIsLoading(true)
    var existingItem = await getExistingItem();
    if(!isObjectNull(existingItem)){
      await addExistingItemAsync(existingItem);
    }else{
      var productToAdd = prepareProductToAdd();
      await window.Snipcart.api.cart.items.add(productToAdd)
    }

    // setIsLoading(false)
    closePopup();
  }

  const prepareProductToAdd = () =>{
    const customFields = selectedAddOns.map((selectedAddOn) => {
      const newSelectedAddOn = {...selectedAddOn, 
        options: convertToOptions(selectedAddOn),
        value: selectedAddOn.choices && selectedAddOn.choices != "" ? 
          selectedAddOn.value:
          selectedAddOn.quantity,
        type: "dropdown",
        uniqueId: selectedAddOn.id
      }
      return newSelectedAddOn
    })

    const transformedProduct = {...product,
      quantity,
      customFields,
      url: storeURL,
      price: product.price,
    }
    delete transformedProduct['image']

    console.log('Customised product')
    console.log(transformedProduct)

    return transformedProduct;
  }

  const getExistingItem = async () => {
    var items = await getAllCartItemsAsync();
    const existingItem = isObjectNull(items) ? null : items.find((item) => {
      var compareAddOns = item.customFields.filter(field => field.value > 0 ).map(field => field.id + "x" + field.value).join(",") === selectedAddOns.filter(addOn => addOn.quantity > 0).map(addOn => addOn.id + "x" + addOn.quantity).join(",");
      return compareAddOns && item.id === product.id;
    });

    return existingItem;
  }

  const  addExistingItemAsync = async (existingItem) => {
    try {
      await window.Snipcart.api.cart.items.update({
        uniqueId: existingItem.uniqueId,
        quantity: existingItem.quantity + quantity
    });
    eventBus.dispatch(itemEvents.existingItemAdded, existingItem);

    } catch (error) {
        console.log(error)
    }
  }


  useEffect(() => {
    console.log('addOns changed in ItemDetailPopup');
    console.log(addOns);
    const addOnAttributesTemp = convertToAttributes(addOns);
    setAddOnAttributes(addOnAttributesTemp)

    const newSelectedAddOns = addOns.map((addOn) => {
      const selectedAddOn = selectedAddOns.find(a => a.id === addOn.id)
      const newAddOn = {...addOn, value: selectedAddOn ? selectedAddOn.value : (addOn.choices? '' : 0)}
      return newAddOn
    })
    setSelectedAddOns(newSelectedAddOns)

    validateProduct(addOns, setIsProductValid);
  }, [addOns])

  useEffect(() => {
    function handleClickOutside(event) {
      console.log("Use ref " + containerRef)
      let popupRootElement = event.target;
      while (popupRootElement.parentElement) {
        if (popupRootElement.classList.contains('popup-content') || 
          popupRootElement.classList.contains('ant-select-dropdown')) {
          return;
        }
        popupRootElement = popupRootElement.parentElement;
      }

      popupRef.current.close();
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef])

  const onPlusBtnClick = () => {
    setQuantity(quantity + 1);
  }
  const onMinusBtnClick = () => {
    if(quantity != 1){
      setQuantity(quantity - 1);
    }
  }

  const onChangeQuantityAddOn = (addOnItems) => {
    console.log("addOnItems");
    console.log(addOnItems);
    console.log("addOns on onChangeQuantityAddOn");
    console.log(addOns)
    var totalAddOnPrice = addOnItems.map(item => item.quantity * item.price).reduce(function (accumulator, currentValue) {
        return accumulator + currentValue;
      }, 0)
    setTotalPrice(product.price + totalAddOnPrice);

    validateProduct(addOns, setIsProductValid);
    setSelectedAddOns(addOnItems);
  }

  const storeURL = process.env.GATSBY_STORE_URL;
  const contentStyle = {
    width: "600px",
  };
  
  return (
    <div className='popup-container'
    ref={containerRef}
    >

      <Popup
          open = {isOpen}
          modal
          ref={popupRef}
          onClose ={closePopup}
          contentStyle={contentStyle}
          closeOnDocumentClick={false}
        >
            <div className ="modal__product">
            {/* <a className="close-button" onClick={close}>
              &times;
            </a> */}
            <div className='modal__header'>
                <div className='product__dietary'>
                    {product.dietaries.sort().map(dietary => (
                      <DietaryIcon dietary={dietary} key={dietary.id}></DietaryIcon>
                    ))}
                </div>

                <div className='product__image'>
                  <figure>
                    <Img sizes={product.image.sizes}  className="img-fluid image" />
                  </figure>
                </div>

            </div>

            <div className='modal__container'>
              <div className="product__info mx-3">
                  <h4 className='product__name text-bolder'>{product.name}</h4>
                  <span className='product__price text-bolder'>{`$${product.price}`}</span>
                  <p className='product__description'>{product.description}</p>
                </div>

            
              <div className="product__options">
                  {isObjectNull(addOns) === false && <CatalogueAddOns onChangeQuantityAddOn={onChangeQuantityAddOn} title={product.name} addOns={addOns} />}
              </div>

                <div className="popup__manipulations mx-3 row">
                  <div className='manipulation__adjust-quantity px-0 col-4 col-md-3'>
                    <button className="button button-round me-2 minus" onClick={onMinusBtnClick}><FaMinus fontSize="0.5em"/></button>
                    {quantity} 
                    <button className="button button-round ms-2 plus" onClick={onPlusBtnClick}><FaPlus fontSize="0.5em"/></button>
                  </div>
                  <div className='manipulation__add-side-cart  col-8 col-md-9'>
                    <button disabled={isLoading || !isProductValid}
                          title={isProductValid ? "" : "Please select required options"}
                          className={isLoading ? "button ButtonAddToCart RedButton__Loading" : "button RedButton ButtonAddToCart"}
                          onClick={async () => { await handleAddItem();}} >
                      { isLoading &&
                        <div className="spinner-border me-1" role="status">
                        </div>
                      }
                      <span>Add {quantity} to order {`$${(totalPrice * quantity).toFixed(1)}`}</span>
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </Popup>

    </div>
   
  );
};

export default ItemDetailPopup;

function validateProduct(addOns, setIsProductValid) {
  let isProductValidOnChange = true;
  for (const addOn of addOns) {
    if (addOn.isRequired && !addOn.value) {
      isProductValidOnChange = false;
      break;
    }
  }
  setIsProductValid(isProductValidOnChange);
}

