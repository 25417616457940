import React, { useState, useEffect, useRef } from "react";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import { eventBus } from "../event-buses/event-bus";
import { checkoutEvents, itemEvents } from "../event-buses/event-names";
import { TiShoppingCart } from "react-icons/ti";
import { toast } from "react-toastify";
import { getAllCartItemsAsync } from "../shared/cart-helper";
import { Affix } from 'antd';
import {dailyClosedPeriod, minutesDiff, getLocalDateTime, getCurrentAustraliaDateTime, getTimeString} from '../shared/date-helper'
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { getDateTimeFromHHMM } from "../shared/date-helper";


const initialPickupTime = getLocalDateTime("now", "en-US", "Australia/Sydney");
initialPickupTime.setMinutes(initialPickupTime.getMinutes() + 30);

const currentTime = getLocalDateTime("now", "en-US", "Australia/Sydney");
const getMinTimePicker = () =>{
  if(dailyClosedPeriod == null)
  {
      return currentTime;
  }
  return currentTime > dailyClosedPeriod.endDate ? currentTime : dailyClosedPeriod.endDate;
}

const SideCart = (
  {
    storeSetting,
    menuDisclaimerContent
  }) => {

  const [items, setItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [isCartValid, setIsCartValid] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [pickupTime, setPickupTime] = useState(initialPickupTime);
  const [newEventTime, setNewEventTime] = useState(new Date());
  const [isCheckoutButtonLoading, setIsCheckoutButtonLoading] = useState(false);

  const refContainer = useRef();

  const handleClickCheckOut = () => {
    if (cartTotal < 30) {
      toast.error("Currently we only accept order higher than $30", {autoClose: 5000});
      return;
    }

    const isTimeErrorMessage = isTimeError();
    if (isTimeErrorMessage) {
      toast.error(isTimeErrorMessage)
      return;
    }
    
    setIsCheckoutButtonLoading(true);
    setTimeout(() => {
      eventBus.dispatch(checkoutEvents.checkoutClicked);
    }, 1000)
  }

  const handleCartChange = () => {
    getAllCartItemsAsync().then(allItems => {
      setItems(allItems);
      setIsCartLoading(false);
    });
  };
  
  const isTimeError = () => {
    const currentStoreSetting = storeSetting;
    console.log('Pick up time');
    console.log(pickupTime);
    console.log('Pick up time - current store setting');
    console.log(currentStoreSetting);
    if(!pickupTime || isNaN(new Date(pickupTime))){
      return `Please check for pickup time before continue to process this order`;
    }
    const settingStoreAvailability = currentStoreSetting.storeOpeningSetting;
    const openDateTime = getDateTimeFromHHMM(settingStoreAvailability.openHour, settingStoreAvailability.openMinute)
    const closeDateTime = getDateTimeFromHHMM(settingStoreAvailability.closeHour, settingStoreAvailability.closeMinute)

    if(pickupTime <= openDateTime || pickupTime >= closeDateTime){
      const openTimeString = getTimeString(
        currentStoreSetting.storeOpeningSetting.openHour,
        currentStoreSetting.storeOpeningSetting.openMinute
      ) 
      const closeTimeString = getTimeString(
        currentStoreSetting.storeOpeningSetting.closeHour,
        currentStoreSetting.storeOpeningSetting.closeMinute
      ) 
      return `Our pick-up hours are ${openTimeString} - ${closeTimeString}. Thank you very much`;
    }

    var now = getCurrentAustraliaDateTime();

    if(now.getTime() > pickupTime.getTime()){
      return `The pickup time is in the past. Please select at least 15 minutes from now.`;
    }
    

    var differenceInMinutes = minutesDiff(pickupTime, now);

    if( differenceInMinutes < 15){
      return `The pick-up time should be at least 15 minutes from now. Thank you very much`;
    }
  }

  const isTimeErrorWithClosedPeriods = (newSetting) => {
    if (storeSetting.todayClosedPeriods) {
      const closedPeriod = storeSetting.todayClosedPeriods.find(p => p.start <= pickupTime && pickupTime <= p.end)
      if (closedPeriod){
        return closedPeriod.closedNotice
      }
    }

    return false;

  }

  const handleItemAdded= (item) => {
    clearTimeout(window.timer);
    window.timer = setTimeout(() => {
      toast.success(`${item.name} \n has been added to cart`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }, 100);

    handleCartChange();

  }

  const handleItemUpdated= (item) => {
    clearTimeout(window.timer);
    window.timer = setTimeout(() => {
      toast.success(`${item.name} \n has been updated`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }, 100);

    handleCartChange();

  }

  const handleStartCartChange = () => {
    setIsCartLoading(true);
  };

  const handleClearCartAsync = async () => {
    handleStartCartChange();
    console.log("Start cart change");
    if (window.Snipcart === null || window.Snipcart === undefined) {
      return;
    }

    var allItems = await getAllCartItemsAsync();
    allItems.map(async (item) => {
      await removeCartItemAsync(item);
    });
    handleCartChange();

  };

  const increaseCartItem = async (uniqueId) => {
    handleStartCartChange();
    const currentItem = items.find((i) => i.uniqueId === uniqueId);

    await window.Snipcart.api.cart.items
    .update({
      uniqueId: uniqueId,
      quantity: currentItem.quantity + 1,
    });

  }

  const removeCartItemAsync = async (uniqueId) =>{
      await window.Snipcart.api.cart.items.remove(uniqueId);
  }
  const decreaseCartItem = async (uniqueId) => {
    handleStartCartChange();
    console.log(uniqueId);
    const currentItem = items.find((i) => i.uniqueId === uniqueId);
    console.log(currentItem);
    if (currentItem.quantity > 1) {
      await window.Snipcart.api.cart.items
        .update({
          uniqueId: uniqueId,
          quantity: currentItem.quantity - 1,
        })
        .then((item) => {
          console.log("New item updated");
          console.log(item);
          handleCartChange();
        });
    } else {
      await removeCartItemAsync(uniqueId);
    }
  };

  useEffect(() => {
    if(window.Snipcart){
      updateMetaDatapickupTimeAsync(pickupTime);
    }
  }, [pickupTime]);

  const updateMetaDatapickupTimeAsync = async (pickupTimeAsDate) =>{
      await window.Snipcart.api.cart.update({
        metadata: {
            "pickupTime": pickupTimeAsDate.toLocaleString("en-US", {hour12: false}),
        },
    });
  }
  useEffect(() => {
    const onCartReady = async () => {
      console.log("Snipcart object");
      console.log(window.Snipcart);

      var allItems = await getAllCartItemsAsync();
      setItems(allItems);

      window.Snipcart.events.on('cart.confirmed', async () => {
          await handleClearCartAsync();
      });

      window.Snipcart.events.on("item.added", (item) => {
        handleItemAdded(item);
      });

      window.Snipcart.events.on("item.updated",(item) => {
        console.log("New item updated");
        console.log(item);
        handleItemUpdated(item);
      });

      window.Snipcart.events.on("item.removed", (item) => {
        console.log("New item removed");
        console.log(item);
        handleCartChange();
      });
    };

    if (window.Snipcart) {
      console.log("cart-ready");
      onCartReady();
    }

    document.addEventListener("snipcart.ready", onCartReady);

    eventBus.on(itemEvents.itemAdding, (data) => {
      handleStartCartChange();
    });

    eventBus.on(itemEvents.existingItemAdded, (data) => {
      handleItemAdded(data);
    });

    eventBus.on(checkoutEvents.checkoutTodayClosedPeriodRefreshed, (newSetting) => {
      setNewEventTime(new Date())
    })

    return () => {
      eventBus.remove(itemEvents.itemAdding);
      document.removeEventListener("snipcart.ready", onCartReady);
    };
  }, []);

  useEffect(() => {
    const sum = items.reduce(
      (total = 0, item) => total + item.totalPrice,
      0
    );
    setCartTotal(sum.toFixed(2));

    // if (items && items.length > 0 && sum >= 30 && !isTimeError()) {
    //   setIsCartValid(true);
    // } else {
    //   setIsCartValid(false);
    // }
  }, [items, pickupTime]);

  useEffect(() => {
    console.log("Side Car : New Event Time = " + newEventTime)
    console.log(storeSetting)
    if (storeSetting.isLoading)
      return;
    setIsCheckoutButtonLoading(false);
    const isError = isTimeErrorWithClosedPeriods();
    if(isError){
      toast.error(isError, {autoClose: 5000});
    }
    else {
      document.location.href = document.location.origin + '/#/cart'
    }
  }, [newEventTime])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const onClickOutside = (e) => {
        if (refContainer.current && !refContainer.current.contains(e.target)) {
          setIsShow(false);
        }
      };

      const onResize = () => {
        setIsShow(false);
      };

      document.addEventListener("mousedown", onClickOutside);
      document.addEventListener("resize", onResize);
      return () => {
        document.removeEventListener("mousedown", onClickOutside);
        document.removeEventListener("resize", onResize);
      };
    }
  }, []);


  return (
  <div className="CartContent" ref={refContainer}>
      <Affix offsetTop={50}>
       <div>
          <div
            className="toggle-cart"
            onClick={() => {
              setIsShow(!isShow);
            }}
          >
           <TiShoppingCart />
        </div>

        <div className={`CartContentInner ${isShow ? "show" : ""}`}>
          <div className="CartHeader CartContentItem">
            <div className="HaderLabel">
              <p className="HeaderLabelText">Cart</p>
            </div>
            <div className="CartContentSpinner">
              {isCartLoading ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              ) : (
                []
              )}
            </div>
            <div className="CartClear">
              <button
                disabled={!storeSetting.isEnableCheckOut}
                className="RedButton"
                onClick={ async () => await handleClearCartAsync()}
              >
                Clear
              </button>
            </div>
          </div>
          <div className="datePicker">


<LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopTimePicker
          disabled={!storeSetting.isEnableCheckOut}
          label="Pick-up time"
          value={pickupTime}
          ampm={false}
          inputFormat="HH:mm"
          minTime={dayjs(getMinTimePicker())}
          maxTime={dailyClosedPeriod && dayjs(dailyClosedPeriod.startDate)}
          onChange={(newTime) => setPickupTime(newTime ? newTime.toDate() : null)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
          </div>
          <div className="menuDisclaimer CartContentItem text-center">
            <span>
              {menuDisclaimerContent}
            </span>
          </div>
          <div className="CartItemList CartContentItem">
            {items && items.length > 0 ? (
              items.map((item) => (
                <div key={item.uniqueId}  className="CartItem">
                  <div className="CartItemInfo">
                    <div className="CartItemName">
                      <p className="CartItemInfo__name fs-7 fw-bold">
                        <span id={item.id} className="Name">{item.name}</span>
                      </p>
                      <p className="CartItemInfo__addOns fs-7 fst-italic">
                        {item.customFields && item.customFields.length > 0 && item.customFields
                          .filter(field => field.value && field.value !== 0 && field.value !== '0')
                          .map(field => field.value > 0 ?
                            field.name + " x" + field.value :
                            field.name + " : "+ field.value).join(", ")}
                      </p>
                    </div>
                    <div className="CartItemPrice fw-normal fs-7">
                        $
                        <span className="CartItemTotalPrice">
                            {item.totalPrice}
                        </span>
                    </div>
                  </div>
                  <div className="CartItemManipulation d-flex justify-content-between mt-4">
                    <div className="d-flex flex-row">
                      <div className="CartItemClear"><button className="btn RedButton RedButtonControl" onClick={async() => await removeCartItemAsync(item.uniqueId)}><FaTrash></FaTrash></button></div>
                    </div>
                    <div className="CartItemRemove d-flex align-items-center">
                        <button
                          className="btn RedButton RedButtonControl"
                          onClick={async() => await decreaseCartItem(item.uniqueId)}
                        >
                          <FaMinus />{" "}
                        </button>
                        <span className="CartItemQuantity fw-bolder fs-7 px-2">
                          X
                          <span className="CartItemQuantityLabel">
                            {item.quantity}
                          </span>
                        </span>
                        <button
                          className="btn RedButton RedButtonControl"
                          onClick={async () => await increaseCartItem(item.uniqueId)}
                        >
                          {" "}<FaPlus />
                        </button>
                      </div>
                  </div>
                </div>               
              ))
            ) : (
              <p>EMPTY</p>
            )}
          </div>
          <div className="CartSummary CartContentItem snipcart-summary">
            <div className="TotalAmount">
              Total: <span className="Amount">${cartTotal}</span>
            </div>
            <div className="checkoutBtnContainer">
              { storeSetting.isEnableCheckOut ? (
                //!isCartValid ? (
                <button 
                  onClick={handleClickCheckOut}
                  disabled={isCheckoutButtonLoading}
                  className="CheckOutButton RedButton">
                  {
                    isCheckoutButtonLoading &&
                    <div className="spinner-border spinner-border-sm me-1" role="status">
                      <span className="sr-only"></span>
                    </div>
                  }
                  Order for pickup
                </button>
                  
                  // <button
                  //   onClick={onPreventCheckout}
                  //   className="CheckOutButton RedButton "
                  // >
                  //   Order for pickup
                  // </button>
                  // ) : (
                  //   <button className="CheckOutButton RedButton snipcart-checkout">
                  //     Order for pickup
                  //   </button>
                  // )
                ) : (
                  <button disabled className="CheckOutButton">
                     Pickup is not available now
                  </button>
                )
              }
            </div>
            <div className="uber-delivery">
              <a target="_blank" href="https://www.ubereats.com/au/store/banhmi-bites/Exgb3wfhU5Se8lsnq-LMUA?diningMode=DELIVERY">Delivery to your location with Uber Eats</a>
            </div>
        </div>
        
         </div>
      </div>
      </Affix>  
  </div> 
  

  );
};

export default SideCart;
