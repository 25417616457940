import axios from 'axios';

export const isMonday = () => {
    return (new Date()).getDay() === 1;
}

export let dailyClosedPeriod= null;

export const getToDayAsText = () => {
    var today = (new Date()).getDay();
    var dayAsText = ""
    switch(today){
        case 0:
            dayAsText = "Sunday"
            break;
        case 1:
            dayAsText = "Monday"
            break;
        case 2:
            dayAsText = "Tuesday"
            break;
        case 3:
            dayAsText = "Wednesday"
            break;
        case 4:
            dayAsText = "Thursday"
            break;
        case 5:
            dayAsText = "Friday"
            break;
        case 6:
            dayAsText = "Saturday"
            break;
        default:
            break;
    }
    return dayAsText;
}

export const getLocalDateTime = (date, locale, tzString) => {
    return new Date((date === "now" ? new Date() : new Date(date)).toLocaleString(locale, {timeZone: tzString}));
}

export const getCurrentAustraliaDateTime = () => {
    return getLocalDateTime("now", "en-US", "Australia/Sydney");
}

export const getAustraliaDateTime = (date) => {
    return getLocalDateTime(date, "en-US", "Australia/Sydney");
}

export const getDateTimeFromHHMM = (hourString, minuteString) => {
    const currentAustraliaDateTime = getCurrentAustraliaDateTime()
    const currentLocalDateTime = new Date()
    const offsetDateTimeInMilliseconds = currentLocalDateTime - currentAustraliaDateTime
    const offsetDateTimeInMinutes = offsetDateTimeInMilliseconds / 1000 / 60
    const offsetDateTimeInHours = Math.round(offsetDateTimeInMinutes / 60) 

    currentLocalDateTime.setHours(Number(hourString) - offsetDateTimeInHours)
    // currentLocalDateTime.setHours(Number(hourString))
    currentLocalDateTime.setMinutes(Number(minuteString))
    currentLocalDateTime.setSeconds(0)
    return currentLocalDateTime
}



export const getTimeString = (hourString, minuteString) => {
    const addZero= (numberString) => {
        return numberString ? (numberString.toString().length > 1 ? numberString : "0" + numberString) : "00";
    }
    const formattedHourString = addZero(hourString)
    const formattedMinuteString = addZero(minuteString)
    return formattedHourString + ":" + formattedMinuteString
}

export const fetchCurrentClosedPeriodAsync = async () => {
    // var netlifyFunctionFetchClosedPeriodUrl = process.env.GATSBY_STORE_URL + ".netlify/functions/fetch-closed-period"
    // var result = await axios.post(netlifyFunctionFetchClosedPeriodUrl)
    return null;
    // var currentClosedPeriod = getCurrentClosedPeriod(result.data.data)
    // return currentClosedPeriod;
}

export const getTodayClosedPeriodsAsync = async () => {
    var netlifyFunctionGetTodayClosedPeriodsUrl = process.env.GATSBY_STORE_URL + ".netlify/functions/get-today-closed-periods"
    var result = await axios.post(netlifyFunctionGetTodayClosedPeriodsUrl)
    result.data = result.data.map(p =>  {
        return {
            ...p,
            start: new Date(p.start),
            end: new Date(p.end)
        }   
    })
    console.log("Today closed periods");
    console.log(result);
    return result;
}

// const getCurrentClosedPeriod = (data) => {
//     var closePeriodArr = data.allClosePeriods;
//     var currentClosedPeriod = null;
//     var todayAsText = getToDayAsText();
//     for (var i = 0; i < closePeriodArr.length; i++) {
//         var closePeriod = closePeriodArr[i];
//         if(!closePeriod.isActive)
//             continue;
            
//         if(closePeriod.start != null && closePeriod.end != null ){
//             var transformedClosePeriod = transfromClosePeriod(closePeriod);
//             var startDateAsTimeStamp = transformedClosePeriod.startDate.getTime();
//             var endDateAsTimeStamp = transformedClosePeriod.endDate.getTime();
//             var nowDateAsTimeStamp = transformedClosePeriod.currentDate.getTime();
            
//             if(startDateAsTimeStamp < nowDateAsTimeStamp && endDateAsTimeStamp > nowDateAsTimeStamp){
//                 currentClosedPeriod = closePeriod;
//                 break;
//             }
//         }
//         else if(closePeriod.dayOfTheWeek !== null && closePeriod.dayOfTheWeek === todayAsText){
//             currentClosedPeriod = closePeriod;
//             break;
//         }
//     }
//     return currentClosedPeriod;
// }


// const transfromClosePeriod = (closePeriod) => {
//     var startDate = getLocalDateTime(closePeriod.start, "en-US", "Australia/Sydney");
//     var endDate = getLocalDateTime(closePeriod.end, "en-US", "Australia/Sydney");
//     var currentDate = getLocalDateTime("now", "en-US", "Australia/Sydney");

//     if(closePeriod.dayOfTheWeek?.toLowerCase() === "all"){
//         var currentDateToSet = currentDate.getDate();

//         var dateDifference = endDate.getDate() - startDate.getDate();
//         if(dateDifference === 1){
//             currentDateToSet -= 1;
//         }
//         startDate.setDate(currentDateToSet)
//         startDate.setMonth(currentDate.getMonth())
//         startDate.setFullYear(currentDate.getFullYear())

//         endDate.setDate(currentDateToSet + dateDifference);
//         endDate.setMonth(currentDate.getMonth())
//         endDate.setFullYear(currentDate.getFullYear())

//         dailyClosedPeriod = {startDate, endDate};
//     }
  
//     console.log((closePeriod.dayOfTheWeek !== "" ? "closePeriod_" + closePeriod.dayOfTheWeek + ":" : "closePeriod:") + " current date - start date - end date");
//     console.log(currentDate + " - " +  startDate + " - " + endDate + "-");  

//     return { startDate, endDate, currentDate };
//   }



  export const minutesDiff = (dt1, dt2) =>
  {
   var diffMin =(dt2.getTime() - dt1.getTime()) / 1000;
   diffMin /= 60;
   return Math.abs(Math.round( diffMin ));
  }