export const itemEvents = {
    itemAdding : 'item-adding',
    existingItemAdded : 'existing-item-added',
}

export const checkoutEvents = {
    checkoutClicked: 'checkout-clicked',
    checkoutTodayClosedPeriodRefreshed: 'checkout-today-closed-periods-refreshed' 
}

export const noticeEvents = {
    noticeUpdating: 'notice-updating'
}

export const navBarEvents = {
    narBarSelectedItemChanging: 'nav-bar-selected-item-changing'
}